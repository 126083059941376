<template>
  <b-list-group-item
    tag="div"
    class="d-flex justify-content-between flex-wrap"
    @contextmenu.prevent="showContextMenu"
  >

    <!-- context -->
    <vue-context
      ref="menu"
      :close-on-scroll="false"
    >
      <li
        v-for="(option, index) in contextMenuOptions"
        :key="index"
        class="context-menu-item"
        @click.prevent="contextMenuOptionClicked(option.action)"
      >
        <span>{{ option.name }}</span>
      </li>
    </vue-context>

    <!-- Input Type -->
    <b-col
      md="2"
      xl="2"
    >
      <b-form-group
        label="Input type"
      >
        <validation-provider
          #default="{ errors }"
          :name="`Input type`"
          rules="required"
          :vid="`Input type${fieldIndex}`"
        >
          <v-select
            v-model="fieldType"
            :options="getOptions"
            label="text"
            class="bg-white"
            :clearable="false"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :reduce="option => option.value"
            placeholder="Choose field type"
            @input="onChangeFieldsDataType"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <template
        v-if="showSubItemsFields(fieldsData) && fieldsData.options"
      >
        <div
          v-for="(option, index) in fieldsData.options"
          :key="index"
          style="height:65px;padding-top: 33px"
          class="d-flex justify-content-center mb-1"
        >
          <b-form-checkbox
            v-if="fieldsData.type === formBuilderFieldTypes.CHECKBOX"
            v-model="fieldsData.default"
            unchecked-value="not_accepted"
            :value="option"
            :checked="option === fieldsData.default"
          >
            Default value
          </b-form-checkbox>
          <b-form-checkbox
            v-else
            v-model="fieldsData.default"
            unchecked-value="not_accepted"
            :value="option || index"
            :checked="option === fieldsData.default"
          >
            Default value
          </b-form-checkbox>
        </div>
      </template>
    </b-col>

    <template
      v-if="fieldsData.type !== formBuilderFieldTypes.PLAIN_TEXT"
    >
      <!-- Input Label -->
      <b-col
        md="2"
        xl="2"
      >
        <b-form-group
          label="Input label"
        >
          <validation-provider
            #default="{ errors }"
            :name="`Label ${fieldIndex}`"
            rules="required"
          >
            <b-form-input
              v-model="fieldsData.label"
              :class="{'is-invalid': errors.length > 0 }"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <div
          v-if="showSubItemsFields(fieldsData) && fieldsData.options"
        >
          <div
            v-for="(option, index) in fieldsData.options"
            :key="index"
            class="mb-1"
          >
            <legend class="bv-no-focus-ring col-form-label pt-0">
              Value
            </legend>
            <b-form-input
              v-model="fieldsData.options[index]"
            />
          </div>
          <div
            v-if="fieldsData.type !== 'checkbox' || (fieldsData.type === 'checkbox' && fieldsData.options.length < 1)"
            class="d-flex justify-content-start"
          >
            <b-button
              variant="outline-primary"
              @click="addOption"
            >
              Add
            </b-button>
          </div>
        </div>
      </b-col>
    </template>

    <!-- Field Key -->
    <b-col
      :md="fieldsData.type === formBuilderFieldTypes.GROUP_SELECTION ? 2 : 3"
      :xl="fieldsData.type === formBuilderFieldTypes.GROUP_SELECTION ? 2 : 3"
    >
      <b-form-group
        label="Field Key"
      >
        <validation-provider
          #default="{ errors }"
          :name="`Key ${fieldIndex}`"
          rules="required"
        >
          <b-form-input
            v-model="fieldsData.name"
            :class="{'is-invalid': errors.length > 0 }"
            @change="replaceValue"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <div
        v-if="showSubItemsFields(fieldsData) && fieldsData.options"
      >
        <div
          v-for="(option, index) in fieldsData.options"
          :key="index"
          class="mb-1"
          style="padding-top: 27px"
        >
          <SquareIcon
            size="21"
            icon="Trash2Icon"
            class="cursor-pointer"
            @onIconClick="openRemoveOptionConfirmationModal(index, option)"
          />
        </div>
      </div>
    </b-col>

    <!-- Mapping field -->
    <template
      v-if="showMappingFields(fieldsData)"
    >
      <!-- Dropdown -->
      <b-col
        md="2"
        xl="2"
      >
        <b-form-group
          label="Mapping field"
        >
          <v-select
            v-model="fieldsData.attribute_field_name"
            :options="filteredFollowingField"
            label="text"
            class="bg-white"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :reduce="option => option.value"
            placeholder="Select option"
          />
        </b-form-group>
      </b-col>
    </template>

    <!-- File Name -->
    <template
      v-if="fieldsData.type === formBuilderFieldTypes.FILE_UPLOAD"
    >
      <b-col
        md="2"
        xl="2"
      >
        <b-form-group
          label="File Name"
        >
          <validation-provider
            #default="{ errors }"
            :name="`File Name ${fieldIndex}`"
            rules="required"
          >
            <b-form-input
              v-model="fieldsData.default"
              :class="{'is-invalid': errors.length > 0 }"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </template>

    <!-- Group Type Select -->
    <b-col
      v-if="fieldsData.type === formBuilderFieldTypes.GROUP_SELECTION"
      md="2"
      xl="2"
    >
      <b-form-group
        label="Group Type"
      >
        <validation-provider
          #default="{ errors }"
          :name="`Group Type ${fieldIndex}`"
          rules="required"
        >
          <v-select
            v-model="fieldsData.default"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="groupTypeOptions"
            :clearable="false"
            label="text"
            class="bg-white"
            :reduce="option => option.value"
            :placeholder="'Select Group Type'"
            @input="choseGroupType"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>

    <b-col
      v-if="fieldsData.type === formBuilderFieldTypes.PLAIN_TEXT"
      md="6"
      xl="6"
    >
      <b-form-group
        label="Plain Text"
      >
        <editor
          :id="`email-content${fieldsData.id}`"
          ref="myEditor"
          v-model="fieldsData.label"
          :api-key="tinyAPI"
          :init="tinyOptions"
        />
      </b-form-group>
    </b-col>

    <!-- Charge Amount -->
    <template
      v-if="fieldsData.type === formBuilderFieldTypes.PAYMENT_FIELD"
    >
      <b-col
        md="3"
        xl="3"
      >
        <b-form-group
          label="Charge Amount ($)"
        >
          <b-form-input
            v-model="fieldsData.options.charge_amount"
            type="number"
            min="0"
            placeholder="0"
          />
        </b-form-group>
      </b-col>
    </template>

    <!-- Input Hide Price For Group Type -->
    <b-col
      v-if="fieldsData.type === formBuilderFieldTypes.GROUP_SELECTION"
      md="1"
      xl="1"
    >
      <b-form-group
        v-if="!showStepFields(fieldsData)"
        label="Hide Price"
      >
        <b-form-checkbox
          v-model="fieldsData.hide_price"
          switch
        >
          <span class="switch-icon-left">
            <feather-icon icon="CheckIcon" />
          </span>
          <span class="switch-icon-right">
            <feather-icon icon="XIcon" />
          </span>
        </b-form-checkbox>
      </b-form-group>
    </b-col>

    <!-- Input Use Prefill -->
    <b-col
      v-if="showUsePrefill(fieldsData)"
      md="1"
      xl="1"
    >
      <b-form-group
        v-if="!showStepFields(fieldsData)"
        label="Use Prefill"
      >
        <b-form-checkbox
          v-model="fieldsData.use_prefill"
          switch
        >
          <span class="switch-icon-left">
            <feather-icon icon="CheckIcon" />
          </span>
          <span class="switch-icon-right">
            <feather-icon icon="XIcon" />
          </span>
        </b-form-checkbox>
      </b-form-group>
    </b-col>

    <!-- Input Required -->
    <b-col
      v-if="showRequired(fieldsData)"
      md="1"
      xl="1"
    >
      <b-form-group
        v-if="!showStepFields(fieldsData)"
        label="Required"
      >
        <b-form-checkbox
          v-model="fieldsData.required"
          :disabled="fieldsData.readonly"
          switch
        >
          <span class="switch-icon-left">
            <feather-icon icon="CheckIcon" />
          </span>
          <span class="switch-icon-right">
            <feather-icon icon="XIcon" />
          </span>
        </b-form-checkbox>
      </b-form-group>
    </b-col>

    <!-- Input Read Only -->
    <b-col
      v-if="showEditable(fieldsData)"
      md="1"
      xl="1"
    >
      <b-form-group
        v-if="!showStepFields(fieldsData)"
        label="Read Only"
      >
        <b-form-checkbox
          v-model="fieldsData.readonly"
          switch
        >
          <span class="switch-icon-left">
            <feather-icon icon="CheckIcon" />
          </span>
          <span class="switch-icon-right">
            <feather-icon icon="XIcon" />
          </span>
        </b-form-checkbox>
      </b-form-group>
    </b-col>

    <!-- Input Delete -->
    <b-col
      md="1"
      xl="1"
      class="d-flex justify-content-end align-items-start"
    >
      <div
        class="d-flex justify-content-end align-items-center mt-2"
      >
        <feather-icon
          v-if="fieldsData.isVisibility"
          icon="EyeOffIcon"
          size="19"
          class="mr-1"
        />

        <feather-icon
          v-if="fieldsData.isCondition"
          icon="CommandIcon"
          size="19"
          class="mr-1"
        />

        <SquareIcon
          size="21"
          icon="Trash2Icon"
          class="cursor-pointer"
          @onIconClick="removeRow(fieldsData)"
        />
      </div>
    </b-col>

    <!-- Charge Amount -->
    <template
      v-if="fieldsData.type === formBuilderFieldTypes.PAYMENT_FIELD"
    >
      <b-col
        md="12"
        xl="12"
      >
        <b-form-group
          label="Description"
        >
          <b-form-textarea
            v-model="fieldsData.options.description"
            class="description-size"
            rows="3"
          />
        </b-form-group>
      </b-col>

      <b-col sm="6">
        <b-form-group
          label="Payment Plan"
        >
          <b-form-checkbox
            v-model="fieldsData.options.payment_plan_is_enable"
            switch
            :value="true"
          >
            True
          </b-form-checkbox>
        </b-form-group>
      </b-col>

      <b-col sm="6">
        <b-form-group
          label="Max payment allowed"
        >
          <b-form-spinbutton
            v-model="fieldsData.options.max_months"
            min="0"
            max="10"
            class="w-25"
          />
        </b-form-group>
      </b-col>
    </template>

    <!-- Change Field Type Confirmation Modal -->
    <confirmation-modal
      :toggle-modal="confirmationModalHide"
      :title="'Are you sure'"
      :message="'Changing the field type you will lose it’s configuration and conditions may not be working properly. Are you sure you want to proceed? '"
      @close-modal="resetFieldsDataType"
      @confirm="changeFieldsDataType"
    />

    <!-- Remove Option Confirmation Modal -->
    <confirmation-modal
      :toggle-modal="removeOptionConfirmationModalHide"
      :title="'Are you sure'"
      :message="'Removing the option the form conditions may not be working properly. Are you sure you want to proceed? '"
      @close-modal="removeOptionConfirmationModalHide = false"
      @confirm="removeOption"
    />

    <!-- Remove Field Confirmation Modal -->
    <confirmation-modal
      :toggle-modal="removeFieldConfirmationModalHide"
      :title="'Are you sure'"
      :message="'Removing the Field the Form conditions may not be working properly. Are you sure you want to proceed? '"
      @close-modal="removeFieldConfirmationModalHide = false"
      @confirm="confirmRemoveField"
    />

  </b-list-group-item>
</template>

<script>
import {
  BButton,
  BCol,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BListGroupItem,
  BFormTextarea,
  BFormSpinbutton,
} from 'bootstrap-vue'

import { tinyOptions } from '@core/mixins/tinyOptions'
import Editor from '@tinymce/tinymce-vue'

import { ValidationProvider } from 'vee-validate'

import vSelect from 'vue-select'
import { required } from '@core/utils/validations/validations'
import VueContext from 'vue-context'
import useProgramsSetting from '@/views/admin/program/useProgramsSetting'
import ConfirmationModal from '@/views/components/confirmation/ConfirmationModal.vue'

import SquareIcon from '@/views/ui/icon/SquareIcon.vue'

export default {
  name: 'FormBuilderRow',
  components: {
    ValidationProvider,
    BCol,
    BFormGroup,
    BListGroupItem,
    BFormInput,
    BFormCheckbox,
    BButton,
    BFormTextarea,
    BFormSpinbutton,
    ConfirmationModal,
    SquareIcon,
    // ContextMenu,
    Editor,
    vSelect,
    VueContext,
  },
  mixins: [tinyOptions],
  props: {
    fieldsList: {
      type: Array,
      default: () => {},
    },
    fieldsData: {
      type: Object,
      default: () => {},
    },
    fieldIndex: {
      type: Number,
      default: 0,
    },
    followingField: {
      type: Array,
      default: () => {},
    },
    groupTypeOptions: {
      type: Array,
      default: () => {},
    },
    formBuilderType: {
      type: String,
      default: 'form',
    },
  },
  data() {
    return {
      confirmationModalHide: false,
      removeOptionConfirmationModalHide: false,
      removeFieldConfirmationModalHide: false,
      fieldType: null,
      optionToRemoveIndex: null,
      optionToRemoveValue: null,
      fieldToRemoveId: null,
      contextMenuOptions: [
        {
          name: 'Move to Beginning',
          action: 'moveToBeginning',
        },
        {
          name: 'Move to End',
          action: 'moveToEnd',
        },
        {
          name: 'Move Before {field}',
          action: 'moveBefore',
        },
      ],
      required,
    }
  },
  computed: {
    getOptions() {
      const options = []
      const types = this.formBuilderType === 'form' ? this.formBuilderFieldTypes : this.familyFormBuilderFieldTypes
      Object.keys(types).map(objectKey => {
        const value = types[objectKey]
        options.push({ value, text: this.getFieldNameForLayout(value) })
        return value
      })
      return options
    },
    filteredFollowingField() {
      const existingFollowingFields = this.fieldsList
        .filter(el => el.attribute_field_name !== null && el.attribute_field_name !== this.fieldsData.attribute_field_name)
        .map(el => el.attribute_field_name)

      return this.followingField.filter(field => !existingFollowingFields.includes(field.value))
    },
  },
  watch: {
    'fieldsData.readonly': function fn(val) {
      if (val) {
        this.fieldsData.required = false
      }
    },
  },
  created() {
    this.fieldType = this.fieldsData.type
  },
  setup() {
    const {
      formBuilderFieldTypes,
      familyFormBuilderFieldTypes,
    } = useProgramsSetting()
    return {
      formBuilderFieldTypes,
      familyFormBuilderFieldTypes,
    }
  },
  methods: {
    choseGroupType(val) {
      this.fieldsData.options = this.groupTypeOptions.filter(item => item.text === val)[0]?.groups
    },
    addOption() {
      this.fieldsData.options.push('')
    },
    openRemoveOptionConfirmationModal(index, val) {
      this.removeOptionConfirmationModalHide = true
      this.optionToRemoveIndex = index
      this.optionToRemoveValue = val
    },
    removeOption() {
      this.fieldsData.options.splice(this.optionToRemoveIndex, 1)
      this.$emit('removeCondition', {
        id: this.fieldsData.id,
        val: this.optionToRemoveValue,
      })
      this.removeOptionConfirmationModalHide = false
    },
    getFieldNameForLayout(value) {
      const str = value.replace('_', ' ').replace('session', this.$tc(this.$config.session_name))
      const lower = str.toLowerCase()
      return str.charAt(0).toUpperCase() + lower.slice(1)
    },
    showSubItemsFields(item) {
      const showFieldsIn = [this.formBuilderFieldTypes.DROPDOWN, this.formBuilderFieldTypes.CHECKBOX, this.formBuilderFieldTypes.RADIO_BUTTON, this.formBuilderFieldTypes.MULTI_SELECT]
      return showFieldsIn.includes(item.type)
    },
    showMappingFields(item) {
      const notShowFieldsIn = [this.formBuilderFieldTypes.PLAIN_TEXT, this.formBuilderFieldTypes.GROUP_SELECTION, this.formBuilderFieldTypes.FILE_UPLOAD, this.formBuilderFieldTypes.PAYMENT_FIELD]
      return !notShowFieldsIn.includes(item.type)
    },
    showRequired(item) {
      const notShowFieldsIn = [this.formBuilderFieldTypes.PLAIN_TEXT, this.formBuilderFieldTypes.PAYMENT_FIELD]
      return !notShowFieldsIn.includes(item.type)
    },
    showEditable(item) {
      const notShowFieldsIn = [this.formBuilderFieldTypes.PLAIN_TEXT, this.formBuilderFieldTypes.PAYMENT_FIELD]
      return (!notShowFieldsIn.includes(item.type) && this.formBuilderType === 'family')
    },
    showUsePrefill(item) {
      const notShowFieldsIn = [this.formBuilderFieldTypes.PLAIN_TEXT, this.formBuilderFieldTypes.PAYMENT_FIELD]
      return this.formBuilderType === 'form' && !notShowFieldsIn.includes(item.type)
    },
    showStepFields(item) {
      const showFieldsIn = [this.formBuilderFieldTypes.STEP]
      return showFieldsIn.includes(item.type)
    },
    removeRow(field) {
      if (field.isCondition) {
        this.removeFieldConfirmationModalHide = true
        this.fieldToRemoveId = field.id
      } else {
        this.$emit('removeFieldsRow', field.id)
      }
    },
    confirmRemoveField() {
      this.$emit('removeFieldsRow', this.fieldToRemoveId)
      this.removeFieldConfirmationModalHide = false
    },
    replaceValue(val) {
      let str = val.toString().replace(/\s+/g, '_').toLowerCase()

      const checkSameName = this.fieldsList
        .filter(item => str === item.name)
        .filter(item => this.fieldsData.id !== item.id)

      if (checkSameName.length > 0) str = `${str}_${new Date().getTime()}`

      this.fieldsData.name = str
    },
    showContextMenu(event) {
      this.$emit('closeOpenedContextMenu')
      this.$refs.menu.open(event)
    },
    contextMenuOptionClicked(action) {
      this.$emit('moveItem', {
        item: this.fieldsData,
        action,
      })
    },
    onChangeFieldsDataType() {
      if (this.fieldsData.isCondition) {
        this.confirmationModalHide = true
      } else {
        this.fieldsData.type = this.fieldType

        if (this.fieldsData.type === this.formBuilderFieldTypes.PAYMENT_FIELD) {
          this.fieldsData.options = {}
        }
      }
    },
    changeFieldsDataType() {
      this.fieldsData.type = this.fieldType

      if (this.fieldsData.type === this.formBuilderFieldTypes.PAYMENT_FIELD) {
        this.fieldsData.options = {}
      }

      this.confirmationModalHide = false
    },
    resetFieldsDataType() {
      this.fieldType = this.fieldsData.type

      this.confirmationModalHide = false
    },
  },
}
</script>

<style>
  legend {
    white-space: nowrap;
  }
  .description-size {
    width: 50% !important;
  }
</style>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-context.scss';
</style>

<style lang="scss" scoped>
.context-menu-item {
  cursor: pointer;
  padding: 5px;
  &:hover {
    color: #fff;
    background: #7367f0;
  }
}
</style>
